import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`answer?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getByTopic(TopicId, keyword, pageNumber, order) {
        return Api().get(`answer?topicId=${TopicId}&keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`answer/${id}`);
    },
    create(data) {
        return Api().post(`answer`, data);
    },
    update(data) {
        return Api().put(`answer/${data.id}`, data);
    },
    delete(id) {
        return Api().delete(`answer/${id}`);
    }
}