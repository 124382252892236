import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`like?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`like/${id}`);
    },
    toggle(data) {
        return Api().post(`like`, data);
    },
    update(data) {
        return Api().put(`like/${data.id}`, data);
    }
}