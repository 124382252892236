<template>
    <footer aria-label="Site Footer" class="bg-white border-t">
    <div class="mx-auto max-w-screen-xl py-5 px-4 sm:px-6 lg:px-8">
        <div class="text-[10px] text-gray-500">
            Copyright © {{ new Date().getFullYear() }} Sociedade Brasileira de Radioterapia. 
            <a class="text-indigo-600 hover:underline hover:text-indigo-800" href="https://sbradioterapia.com.br/politica-de-privacidade-e-termos-de-uso/">
                Política de Privacidade e Termos de Uso
            </a>
            Desenvolvido por
            <a class="text-indigo-600 hover:underline hover:text-indigo-800" href="http://unimagem.com.br/">
                Unimagem Produções Audiovisuais Ltda.
            </a>
        </div>
    </div>
    </footer>
</template>

<script>
    export default {
        name: 'FootterComponnet',
        data: () => {
            return {
            }
        },
    }
</script>