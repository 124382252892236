<template>
  <main>
        <div id="app" :class="{ 'dark': getDarkMode }">
            <div :class="getAlerts.length > 0 && 'space-y-1 py-3 __alert shadow-sm'" class="fixed top-0 w-full" style="z-index: 999;">
                <AlertComponent v-for="(alert, index) in getAlerts" :key="index" :alert="alert"/>
            </div>
            <router-view  class="flex flex-col w-full flex-1 h-full overflow-x-hidden overflow-y-auto dark:bg-black dark:text-gray-200 dark:border-primary min-h-screen"/>
            <Footer />
        </div>
    </main>
</template>
<script>
import { mapGetters } from 'vuex'
import AlertComponent from './components/helper/AlertComponent.vue'
import Footer from '@/components/footer/FooterComponent.vue';

export default {
    components: {
        AlertComponent,
        Footer
    },
    computed: {
        ...mapGetters(['getAlerts', 'getDarkMode']),
    }
}
</script>

<style lang="scss">

  html {
      font-family:
          Roboto,
          'Source Sans Pro',
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          'Helvetica Neue',
          Arial,
          sans-serif;
      font-size: 16px;
      word-spacing: 1px;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      background-color: white;
  }

  *,
  *::before,
  *::after {
      box-sizing: border-box;
      margin: 0;
  }

  .__alert {
      backdrop-filter: blur(10px);
      background-color: rgba(0, 195, 255, 0.041);
  }

  button {
    cursor: pointer;
  }

  li {
    list-style-type: none;
  }

  ul {
    padding: 0;
  }

</style>