import Api from './Api'

export default {
    get(keyword, pageNumber, filters, order) {
        const params = new URLSearchParams();

        params.append('keyword', keyword);
        params.append('pageNumber', pageNumber);
        params.append('orderColumn', order.column);
        params.append('orderSort', order.sort);
        
        // Serializando os filtros
        Object.keys(filters).forEach(key => {
            filters[key].forEach(value => {
                params.append(`${key}[]`, value);
            });
        });

        return Api().get(`topic`, { params });
    },
    getRecommended(topicId, categoryId) {
        return Api().get(`topic/recommended?topicId=${topicId}&categoryId=${categoryId}`);
    },
    getOne(id) {
        return Api().get(`topic/${id}`);
    },
    create(data) {
        return Api().post(`topic`, data);
    },
    update(data) {
        return Api().put(`topic/${data.id}`, data);
    },
    delete(id) {
        return Api().delete(`topic/${id}`);
    }
}