import router from '../../router'
import TopicService from '../../services/TopicService'

const state = () => ({
    loadingTopics: false,
    // newTopic: localStorage.getItem('newTopic') || { title: "", content: "", anonymous: false, CategoryId: null },
    newTopic: (() => {
        try {
            return JSON.parse(localStorage.getItem('newTopic')) || null;
        } catch (error) {
            localStorage.removeItem('newTopic');
            return { title: "", content: "", anonymous: false, CategoryId: null };
        }
    })(),
    topic: null,
    topics: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_topic: false,
    topicsRecommended: []
})
  
const mutations = {
    SAVE_NEW_TOPIC: (state) => {
        localStorage.setItem('newTopic', JSON.stringify(state.newTopic));
    },
    SET_NEW_TOPIC: (state, payload) => {
        localStorage.setItem('newTopic', JSON.stringify(payload));
        state.newTopic = payload
    },
    SET_TOPIC: (state, payload) => {
        state.topic = payload
    },
    SET_TOPICS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.topics = payload.items
        }else{
            state.topics = [...state.topics, ...payload.items]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_TOPICS_RECOMMENDED: (state, payload) => {
        state.topicsRecommended = payload
    },
    SET_LOADING_TOPICS: (state, payload) => {
        state.loadingTopic = payload
    },
    SET_SHOW_MODAL_TOPIC: (state, payload) => {
        state.show_modal_new_topic = payload
    },
}

const actions = {
    async fetchTopic({commit}, id){
        try{
            commit('SET_LOADING_TOPICS', true)
            const response = await TopicService.getOne(id);
            commit('SET_TOPIC', response.data)
            commit('SET_LOADING_TOPICS', false)
        } catch (error) {
            commit('SET_LOADING_TOPICS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTopics({commit}, { keyword = '', pageNumber = 1, filters, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_TOPICS', true)
            const response = await TopicService.get(keyword, pageNumber, filters, order);
            commit('SET_TOPICS', response.data)
            commit('SET_LOADING_TOPICS', false)
        } catch (error) {
            commit('SET_LOADING_TOPICS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchRecommendedTopics({commit}, {topicId, categoryId} ){
        try{
            commit('SET_LOADING_TOPICS', true)
            const response = await TopicService.getRecommended(topicId, categoryId);
            commit('SET_TOPICS_RECOMMENDED', response.data)
            commit('SET_LOADING_TOPICS', false)
        } catch (error) {
            commit('SET_LOADING_TOPICS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createTopic({commit}, data){
        try{
            commit('SET_LOADING_TOPICS', true)
            const response = await TopicService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/topic/id/${response.data.item.id}`});
            commit('SET_LOADING_TOPICS', false)
            return response.data.item.id
        } catch (error) {
            commit('SET_LOADING_TOPICS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeTopic({commit}, data){
        try{
            commit('SET_LOADING_TOPICS', true)
            const response = await TopicService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/topic/id/${response.data.item.id}`});
            commit('SET_LOADING_TOPICS', false)
        } catch (error) {
            commit('SET_LOADING_TOPICS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteTopic({commit}, id){
        try{
            commit('SET_LOADING_TOPICS', true)
            const response = await TopicService.delete(id);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/`});
            commit('SET_SHOW_MODAL_CONFIRMATION', false)
            commit('SET_LOADING_TOPICS', false)
        } catch (error) {
            commit('SET_LOADING_TOPICS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getNewTopic: state => state.newTopic,
    getTopic: state => state.topic,
    getTopics: state => state.topics,
    getTopicsRecommended: state => state.topicsRecommended,
    getLoadingTopic: state => state.loadingTopic,
    getShowModalNewTopic: state => state.show_modal_new_topic
}

export default {
    state,
    getters,
    mutations,
    actions
};