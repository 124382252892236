import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/page/:pageNumber',
    name: 'ProductSearch',
    component: () => import('@/views/TopicsView.vue')
  },
  {
    path: '/page/:pageNumber/search/:search?',
    name: 'Topics Search',
    component: () => import('@/views/TopicsView.vue')
  },
  {
    path: '/page/:pageNumber/category/:category',
    name: 'Topics Category',
    component: () => import('@/views/TopicsView.vue')
  },
  {
    path: '/topic/id/:id',
    name: 'Topic',
    component: () => import('@/views/TopicView.vue')
  },
  {
    path: '/form_topic_tiny/',
    name: 'Form Topic Tiny MCE',
    component: () => import('@/views/FormTopicTiny.vue'),
  },
  {
    path: '/form_topic/',
    name: 'Form Topic',
    component: () => import('@/views/FormTopic.vue'),
    children: [{
      path: ':topicId', 
      name: 'Editar Topic', 
      component: () => import('@/views/FormTopic.vue')
    }],
  },
  {
    path: '/profile/my-account',
    name: 'Profile',
    component: () => import('@/views/MyProfileView.vue')
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/password-reset/:tokenPass?',
    name: 'Reset Password',
    component: () => import('@/views/PasswordResetView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
    
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 100
      }
    } else {
      return { x: 0, y: 0 };
    }
  },
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`
  window.scrollTo(0, 0);
  next()
})

export default router