import Api from './Api'

export default {
    get(keyword, pageNumber, order) {
        return Api().get(`category?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}&pageSize=1000`);
    },
    getAllAssets() {
        return Api().get(`category/all`);
    },
    getOne(id) {
        return Api().get(`category/${id}`);
    },
    create(data) {
        return Api().post(`category`, data);
    },
    update(data) {
        return Api().put(`category/${data.id}`, data);
    }
}