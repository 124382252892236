import router from '../../router'
import LikeService from '../../services/LikeService'

const state = () => ({
    loadingLikes: false,
    like: null,
    likes: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_like: false
})
  
const mutations = {
    SET_LIKE: (state, payload) => {
        state.like = payload
    },
    SET_LIKES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.likes = payload.itens
        }else{
            state.likes = [...state.likes, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_LIKES: (state, payload) => {
        state.loadingLike = payload
    },
    SET_SHOW_MODAL_LIKE: (state, payload) => {
        state.show_modal_new_like = payload
    },
}

const actions = {
    async fetchLike({commit}, id){
        try{
            commit('SET_LOADING_LIKES', true)
            const response = await LikeService.getOne(id);
            commit('SET_LIKE', response.data)
            commit('SET_LOADING_LIKES', false)
        } catch (error) {
            commit('SET_LOADING_LIKES', false);
            commit('SET_REDIRECT', router.currentRoute.value.path);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchLikes({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_LIKES', true)
            const response = await LikeService.get(keyword, pageNumber, order);
            commit('SET_LIKES', response.data)
            commit('SET_LOADING_LIKES', false)
        } catch (error) {
            commit('SET_LOADING_LIKES', false);
            commit('SET_REDIRECT', router.currentRoute.value.path);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async toggleLike({commit}, data){
        try{
            commit('SET_LOADING_LIKES', true)
            await LikeService.toggle(data);
            commit('SET_SHOW_MODAL_LIKE', false)
            commit('SET_LOADING_LIKES', false)
        } catch (error) {
            commit('SET_LOADING_LIKES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeLike({commit}, data){
        try{
            commit('SET_LOADING_LIKES', true)
            const response = await LikeService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_LIKES', false)
        } catch (error) {
            commit('SET_LOADING_LIKES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getLike: state => state.like,
    getLikes: state => state.likes,
    getLoadingLike: state => state.loadingLike,
    getShowModalNewLike: state => state.show_modal_new_like
}

export default {
    state,
    getters,
    mutations,
    actions
};