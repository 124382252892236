// import SearchService from '../../services/SearchService'

const state = () => ({
    loadingSearches: false,
    search: null,
    searches: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_search: false,
    show_modal_filter: false,
    orderSelect: { name: 'Tópicos Recentes', column: 'id', sort: 'DESC' },
    sortOptions: [
        { name: 'Tópicos Recentes', column: 'id', sort: 'DESC'},
        { name: 'Tópicos Antigos', column: 'id', sort: 'ASC'},
        { name: 'Mais Respondido', column: 'answerCount', sort: 'DESC'},
        { name: 'Mais Avaliado', column: 'likeCount', sort: 'DESC'},
        // { name: 'Respondidos Recentemente', column: 'id', sort: 'ASC'},
    ],
    quantityAnswersSelect: 'all',
    filters: [
        {
            id: 'category',
            name: 'Categorias',
            isOpen: false,
            options: [
            ],
        },
        // {
        //     id: 'author',
        //     name: 'Autores',
        //     isOpen: false,
        //     options: [
        //         // { value: 'all', label: 'Todos os Autores', checked: false },
        //         { value: 'adm', label: 'Administradores', checked: false },
        //         { value: 'me', label: 'Meus Tópicos', checked: false },
        //     ],
        // },
        {
            id: 'creationDate',
            name: 'Data de Criação',
            isOpen: false,
            options: [
                // { value: 'all', label: 'Todas', checked: false },
                { value: 'today', label: 'Hoje', checked: false },
                { value: 'lastWeek', label: 'Última Semana', checked: false },
                { value: 'lastMonth', label: 'Último Mês', checked: false },
                { value: 'lastYear', label: 'Último Ano', checked: false },
            ],
        },
    ],
    show_modal_search: false
})
  
const mutations = {
    SET_SEARCH: (state, payload) => {
        state.search = payload
    },
    SET_SEARCHES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.searches = payload.itens
        }else{
            state.searches = [...state.searches, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_FILTER_CATEGORY: (state, {categories, categorySelect}) => {
        const categoryFilter = state.filters.find(item => item.id === 'category');

        if (categoryFilter) {
            categoryFilter.options = categories.map(category => ({
                id: category.id,
                label: category.name,
                value: category.slug,
                description: category.description,
                parentId: category.parentId,
                checked: category.slug === categorySelect,
            }));
            if(categorySelect && categorySelect !== ""){
                categoryFilter.isOpen = true;
            }
        }
    },
    SET_SELECT_FILTER_QNT_ANSWER: (state, option) => {
        state.quantityAnswersSelect = option
    },
    SET_LOADING_SEARCHES: (state, payload) => {
        state.loadingSearch = payload
    },
    SET_SHOW_MODAL_SEARCH: (state, boolean) => {
        state.show_modal_search = boolean
    },
    SET_SHOW_MODAL_FILTER: (state, boolean) => {
        state.show_modal_filter = boolean
    },

    UPDATE_SORT_OPTION(state, payload) {
        state.sortOptions.forEach(option => {
            option.current = (option.name === payload);
        });
    },
    TOGGLE_FILTER_OPTION(state, { filterId, optionValue }) {
        const filter = state.filters.find(f => f.id === filterId);
        if (filter) {
            const option = filter.options.find(o => o.value === optionValue);
            if (option) {
            option.checked = !option.checked;
            }
        }
    },
    TOGGLER_ORDER(state, option) {
        state.orderSelect = option
    },
}

const actions = {
    updateSortOption({ commit }, optionName) {
        commit('UPDATE_SORT_OPTION', optionName);
    },
    toggleFilterOption({ commit }, payload) {
        commit('TOGGLE_FILTER_OPTION', payload);
    },
    // async fetchSearch({commit}, id){
    //     try{
    //         commit('SET_LOADING_SEARCHES', true)
    //         const response = await SearchService.getOne(id);
    //         commit('SET_SEARCH', response.data)
    //         commit('SET_LOADING_SEARCHES', false)
    //     } catch (error) {
    //         commit('SET_LOADING_SEARCHES', false);
    //         commit('LOG_USER_OUT');
    //         commit('SET_ALERT', {
    //             heading: 'error',
    //             message: error.response.data.message
    //         });
    //     }
    // },
    // async fetchSearches({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
    //     try{
    //         commit('SET_LOADING_SEARCHES', true)
    //         const response = await SearchService.get(keyword, pageNumber, order);
    //         commit('SET_SEARCHES', response.data)
    //         commit('SET_LOADING_SEARCHES', false)
    //     } catch (error) {
    //         commit('SET_LOADING_SEARCHES', false);
    //         commit('LOG_USER_OUT');
    //         commit('SET_ALERT', {
    //             heading: 'error',
    //             message: error.response.data.message
    //         });
    //     }
    // },
    // async createSearch({commit}, data){
    //     try{
    //         commit('SET_LOADING_SEARCHES', true)
    //         const response = await SearchService.create(data);
    //         commit('SET_ALERT', {
    //             heading: 'success',
    //             message: response.data.message
    //         });
    //         commit('SET_SHOW_MODAL_SEARCH', false)
    //         commit('SET_LOADING_SEARCHES', false)
    //     } catch (error) {
    //         commit('SET_LOADING_SEARCHES', false);
    //         commit('SET_ALERT', {
    //             heading: 'error',
    //             message: error.response.data.message
    //         });
    //     }
    // },
    // async updadeSearch({commit}, data){
    //     try{
    //         commit('SET_LOADING_SEARCHES', true)
    //         const response = await SearchService.update(data);
    //         commit('SET_ALERT', {
    //             heading: 'success',
    //             message: response.data.message
    //         });
    //         commit('SET_LOADING_SEARCHES', false)
    //     } catch (error) {
    //         commit('SET_LOADING_SEARCHES', false);
    //         commit('SET_ALERT', {
    //             heading: 'error',
    //             message: error.response.data.message
    //         });
    //     }
    // },
}

const getters = {
    getSearch: state => state.search,
    getSearches: state => state.searches,
    getLoadingSearch: state => state.loadingSearch,
    getShowModalNewSearch: state => state.show_modal_new_search,

    getFilters: state => state.filters,
    getShowModalFilter: state => state.show_modal_filter,
    selectedSortOption(state) {
        return state.sortOptions.find(option => option.current);
    },
    selectedFilters(state) {
        return state.filters.reduce((acc, filter) => {
            acc[filter.id] = filter.options.filter(option => option.checked);
            return acc;
        }, {});
    },
    
    getOrderSelect: state => state.orderSelect,
    getFilterQtyAnswersSelect: state => state.quantityAnswersSelect,
    getSortOptions: state => state.sortOptions,
    getShowModalSearch: state => state.show_modal_search
}

export default {
    state,
    getters,
    mutations,
    actions
};