// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import password from './modules/password';
import email from './modules/email';
import category from './modules/category';
import topic from './modules/topic';
import search from './modules/search';
import answer from './modules/answer';
import like from './modules/like';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        password,
        email,
        category,
        topic,
        search,
        answer,
        like
    }
})
