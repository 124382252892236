const state = () => ({
    isMenuOpen: false,
    authenticateBeforeCheckout: true,
    hideScrollBar: false,
    alerts: [],
    currency: 'R$',
    storeName: process.env.VUE_APP_TITLE,
    darkMode: JSON.parse(localStorage.getItem('theme'))  || false,
    showModalConfirmation: false,
    itensNavbar:  [
        {
            "name": "Home",
            "url": "/#home",
            "svg": '<svg class="h-5 w-5 opacity-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" class=""></path></svg>',
            "sublinks": []
        },
        {
            "name": "Sobre",
            "url": "/about",
            "svg": '<svg class="h-5 w-5 opacity-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" /></svg>',
            "sublinks": []
        },
        // {
        //     name: 'Disciplinas',
        //     path: '/subjects'
        // },
        // {
        //     name: 'Avaliações',
        //     path: '/avaliacoes'
        // },
        // {
        //     "name": "Analytics",
        //     "url": "/",
        //     "svg": '<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" /></svg>',
        //     "sublinks": []
        //     // "sublinks": [
        //     //     {
        //     //         "name": "Revenue",
        //     //         "url": "/Revenue",
        //     //     },
        //     //     {
        //     //         "name": "Refunds",
        //     //         "url": "/Refunds",
        //     //     }
        //     // ]
        // }
    ],
})
  
const mutations = {
    TOGGLE_MENU(state, payload) {
        state.hideScrollBar = payload
        state.isMenuOpen = payload
    },
    TOGGLE_DARK_MODE(state) {
        localStorage.setItem('theme', JSON.stringify(!state.darkMode));
        state.darkMode = !state.darkMode;
    },
    SET_ALERT(state, alert){
        if(alert && alert.message != "Sessão expirada") {
            state.alerts.unshift(alert);
            setTimeout(() => {
                state.alerts.pop();
            }, 5000);
        }
    },
    SET_SHOW_MODAL_CONFIRMATION: (state, payload) => {
        state.showModalConfirmation = payload;
    },
}

const getters = {
    getIsMenuOpen: state => state.isMenuOpen,
    getAlerts: state => state.alerts,
    getDarkMode: state => state.darkMode,
    getShowModalConfirmation: state => state.showModalConfirmation,
    getItensNavbar: state => state.itensNavbar
}

export default {
    state,
    getters,
    mutations
};