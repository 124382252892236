import AnswerService from '../../services/AnswerService'

const state = () => ({
    loadingAnswers: false,
    newAnswer: { content: "", TopicId: null, parentId: null },
    answer: null,
    answers: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_answer: false
})
  
const mutations = {
    SET_NEW_ANSWER: (state, payload) => {
        state.newAnswer = payload
    },
    SET_ANSWER: (state, payload) => {
        state.answer = payload
    },
    SET_ANSWERS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.answers = payload.itens
        }else{
            state.answers = [...state.answers, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_ANSWERS: (state, payload) => {
        state.loadingAnswer = payload
    },
    SET_SHOW_MODAL_ANSWER: (state, payload) => {
        state.show_modal_new_answer = payload
    },
}

const actions = {
    async fetchAnswer({commit}, id){
        try{
            commit('SET_LOADING_ANSWERS', true)
            const response = await AnswerService.getOne(id);
            commit('SET_ANSWER', response.data)
            commit('SET_LOADING_ANSWERS', false)
        } catch (error) {
            commit('SET_LOADING_ANSWERS', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAnswerByTopic({commit}, { TopicId = null, keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_ANSWERS', true)
            const response = await AnswerService.getByTopic(TopicId, keyword, pageNumber, order);
            commit('SET_ANSWERS', response.data)
            commit('SET_LOADING_ANSWERS', false)
        } catch (error) {
            commit('SET_LOADING_ANSWERS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createAnswer({commit, dispatch}, data){
        try{
            commit('SET_LOADING_ANSWERS', true)
            const response = await AnswerService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchAnswerByTopic', {TopicId: data.TopicId});

            commit('SET_LOADING_ANSWERS', false)
        } catch (error) {
            commit('SET_LOADING_ANSWERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeAnswer({commit}, data){
        try{
            commit('SET_LOADING_ANSWERS', true)
            const response = await AnswerService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_ANSWERS', false)
        } catch (error) {
            commit('SET_LOADING_ANSWERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteAnswer({commit, dispatch}, answer){
        try{
            commit('SET_LOADING_ANSWERS', true)
            const response = await AnswerService.delete(answer.id);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchAnswerByTopic', {TopicId: answer.TopicId});
            commit('SET_SHOW_MODAL_CONFIRMATION', false)
            commit('SET_LOADING_ANSWERS', false)
        } catch (error) {
            commit('SET_LOADING_ANSWERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getNewAnswer: state => state.newAnswer,
    getAnswer: state => state.answer,
    getAnswers: state => state.answers,
    getLoadingAnswer: state => state.loadingAnswer,
    getShowModalNewAnswer: state => state.show_modal_new_answer
}

export default {
    state,
    getters,
    mutations,
    actions
};