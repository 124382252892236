import CategoryService from '../../services/CategoryService'

const state = () => ({
    loadingCategories: false,
    category: null,
    categories: (() => {
        try {
            return {
                timestamp: localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')).timestamp : null,
                data: localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')).data : [],
            }
        } catch (error) {
            localStorage.removeItem('categories');
            return [];
        }
    })(),
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_category: false
})
  
const mutations = {
    SET_CATEGORY: (state, payload) => {
        state.category = payload
    },
    SET_CATEGORIES: (state, payload) => {
        // if(payload.pageNumber == 1) {
        //     state.categories = payload.itens
        // }else{
    //     state.categories = [...state.categories, ...payload.itens]
        // }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

        state.categories.data = payload.itens;
        state.categories.timestamp = new Date().getTime() + (3 * 24 * 60 * 60 * 1000); // (24 horas * 60 minutos * 60 segundos * 1000 milissegundos) ao timestamp
        localStorage.setItem('categories', JSON.stringify(state.categories));
    },
    SET_LOADING_CATEGORIES: (state, payload) => {
        state.loadingCategory = payload
    },
    SET_SHOW_MODAL_CATEGORY: (state, payload) => {
        state.show_modal_new_category = payload
    },
}

const actions = {
    async fetchCategory({commit}, id){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.getOne(id);
            commit('SET_CATEGORY', response.data)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCategories({commit}, { keyword = '', pageNumber = 1, order = { name: 'ID', column: 'ID', sort: 'ASC' } }){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.get(keyword, pageNumber, order);
            commit('SET_CATEGORIES', response.data)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAllCategories({commit}){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.getAllAssets();
            commit('SET_CATEGORIES', response.data)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCategory({commit}, data){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_CATEGORY', false)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeCategory({commit}, data){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getCategory: state => state.category,
    // getCategories: state => state.categories,
    getCategories: state => {
        const isExpired = state.categories.timestamp && state.categories.timestamp < new Date().getTime();
        return isExpired || !state.categories.data ? [] : state.categories.data;
    },
    getLoadingCategory: state => state.loadingCategory,
    getShowModalNewCategory: state => state.show_modal_new_category
}

export default {
    state,
    getters,
    mutations,
    actions
};